import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Button from '../../../components/Button';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image1: imageSharp(fluid: { originalName: {eq: "aboutus_07_joinus.jpg"}}) {
          fluid(maxHeight: 368, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        image2: imageSharp(fluid: { originalName: {eq: "aboutus_08_contactus.jpg"}}) {
          fluid(maxHeight: 368, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ image1, image2 }) => {
      return (
      <SectionWrapper className={styles.container} id={i18n('_hash:join-us')}>
          <div className={clsx(styles.innerContainer, styles.innerLeft)} style={{ backgroundImage: `url(${image1.fluid.src})`}}>
            <Typography variant='h3'
              weight='bold'
              className={styles.titleContainer}>
              {i18n('Check out if we have any open positions.')}
            </Typography>
            <div className={clsx(styles.titleContainer, styles.buttonContainer)}>
              <Link to={'https://www.linkedin.com/company/traffilognordic/'} target={'_blank'} style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Join us')}
                </Button>
              </Link>
            </div> 
          </div>          

          <div className={clsx(styles.innerContainer, styles.innerRight)} style={{ backgroundImage: `url(${image2.fluid.src})`}}>
            <Typography variant='h2'
              weight='bold'
              className={styles.titleContainer}>
              {i18n('Can’t find what you’re looking for? We value proactiveness so please just write to us.')}
            </Typography>
            <div className={clsx(styles.titleContainer, styles.buttonContainer)}>
              <Link to={i18n('_url:lets-connect')} style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Contact us')}
                </Button>
              </Link>
            </div> 
          </div>          
      </SectionWrapper>
  )}}/>
);