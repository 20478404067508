import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image: imageSharp(fluid: { originalName: {eq: "aboutus_02_story.png"} }) {
          fluid(maxWidth: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ image }) => {
      return (<SectionWrapper className={styles.container} id={i18n('_hash:our-story')}>          
          <Grid container alignItems='center' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={5} className={styles.textContainer}>
              <Typography variant='h2'
                weight='bold'
                className={styles.title}>
                {i18n('Our story')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('Founded in 2005 by Karsten W. Hjorth as North Securities, the company set out to deliver innovative telematic solutions for complex projects in Denmark. Within a couple of years, the company began its international expansion via a strategic partnership with the Falck Group which lasted for almost a decade.')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('In 2014, the company rebranded to Traffilog Nordic after becoming the independent commercial reseller organisation for the Israeli-based telematics company called Traffilog Ltd (now Questar Auto).')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('Today, Traffilog Nordic is providing solutions in around 16 countries across Europe, North America, and Africa. The company is enabling thousands of businesses in a wide range of segments including public transportation (bus), logistics, utility and emergency services, waste management, mining, shared mobility, and more, to become better, greener, and more efficient when operating their fleets.')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('Traffilog Nordic has in the last years strengthened its focus on the mobility arena becoming the enabler connecting fleet and mobility operators not only to the vehicle but also to new services delivering unique customer experiences. As a solutions partner, Traffilog offers its end-to-end expertise to partners and customers, and reinforced by its own modular and scalable platform, the company has a unique set of tools to offer to everyone interested in expanding its digital portfolio.')}
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={7} className={styles.imageContainer}>
              <Img imgStyle={{ objectFit: 'cover'}}
                objectFit='cover'
                // objectPosition='right center'
                className={styles.image}
                fluid={image.fluid}
                alt={i18n('Platform powered by the modern cloud engine')}/>
            </Grid>

          </Grid>

      </SectionWrapper>
  )}}/>
);