import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import Feature from './Feature';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        icon1: imageSharp(fluid: { originalName: {eq: "Platform-API-icon1.png"} }) {
          fluid(maxWidth: 116, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ icon1 }) => {
      return (<SectionWrapper className={styles.container}>          
          <Grid container alignItems='center' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={12} className={styles.textContainer}>
              <Typography variant='h3'
                weight='bold'
                className={styles.title}>
                {i18n('Our values ')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('These values represent our beliefs and principles. Most importantly, they guide how we work together every day.')}
              </Typography>
            </Grid>
          
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} className={styles.listContainer}>
            <Feature
              image={null}
              title={i18n('Commit to customers')}
              text={i18n('At our core, we are a service company committed to continuously assisting our customers in the progress to achieve their goals and aspirations.')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} className={styles.listContainer}>
            <Feature
              image={null}
              title={i18n('Share responsibility to deliver quality')}
              text={i18n('We feel responsible to act with honesty, integrity, and accountability. We deliver high quality and on time.')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} className={styles.listContainer}>
            <Feature
              image={null}
              title={i18n('Value customer-centric and proactive attitude')}
              text={i18n('Customers are unique and so will be their experience. We strive to deliver a 5-star customer experience because satisfied customers would prefer to work with us. ')}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={0} lg={2} className={styles.dummyItem}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} className={styles.listContainer}>
            <Feature
              image={null}
              title={i18n('Respect and rely on each other')}
              text={i18n('We respect our colleagues, their input, and their hard work. We rely on each other’s superpowers to get the job done and we can only succeed as a team. ')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} className={styles.listContainer}>
            <Feature
              image={null}
              title={i18n('Embrace, enable, and empower innovation')}
              text={i18n('We strive to be the enabler that customers are looking for in today’s world. We are excited to push forward into new spaces, explore and build the new connected world together.')}
            />
          </Grid>

      </SectionWrapper>
  )}}/>
);