import React from "react";
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import Hero from './Hero';
import OurStory from "./OurStory";
import DayToDay from "./DayToDay";
import OurValues from "./OurValues";
import ContactUs from './ContactUs';
import CallToAction from '../CallToAction';
import sitemap from '../../data/sitemap';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.aboutUs}
    url='_url:about-us'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <OurStory />
    <OurValues />
    <DayToDay />
    <ContactUs />
    <CallToAction/>
  </Layout>
);