import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image: imageSharp(fluid: { originalName: {eq: "aboutus_03_daytoday.jpg"} }) {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tb1: imageSharp(fluid: { originalName: {eq: "aboutus_04_tb1.jpg"} }) {
          fluid(maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tb2: imageSharp(fluid: { originalName: {eq: "aboutus_05_tb2.jpg"} }) {
          fluid(maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        tb3: imageSharp(fluid: { originalName: {eq: "aboutus_06_tb3.jpg"} }) {
          fluid(maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ image, tb1, tb2, tb3 }) => {
      return (<SectionWrapper className={styles.container}>          
          <Grid container alignItems='center' className={styles.rowContainer}>
            
            <Grid item xs={12} sm={12} md={12} lg={6} className={styles.textContainer}>
              <Typography variant='h2'
                weight='bold'
                className={styles.title}>
                {i18n('The day to day')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('We embrace work-life balance and flexibility. The company has 3 offices so remote work has been an integral part of our corporate culture for a long time. Furthermore, on a day-to-day, basis, we are grateful to work with colleagues from different nationalities, customers across industries, and partners globally.')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('We are proud of the skills everyone brings and strive to deliver great experiences for our customers. We foster team alignment to keep us all connected and up-to-date with the latest company, project, or partner news. We bring everyone together for in-person company events a few times a year to celebrate and have fun.')}
              </Typography>
              <Typography variant='subtitle2'
                weight='light'
                className={styles.subtitleContainer}>
                {i18n('We support everyone’s personal and professional growth and we try our best to keep a great workplace environment, taking care of one another as a family.')}
              </Typography> 
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={6} className={styles.imageContainer}>
              <Img imgStyle={{ objectFit: 'contain'}}
                objectFit='contain'
                // objectPosition='right center'
                className={styles.image}
                fluid={image.fluid}
                alt={i18n('Platform powered by the modern cloud engine')}/>
            </Grid>

          </Grid>

          <Grid container alignItems='center' className={styles.rowContainer} spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} className={styles.tbImageContainer}>
              <Img imgStyle={{ objectFit: 'none'}}
                objectFit='none'
                // objectPosition='right center'
                className={styles.tbImage}
                fluid={tb1.fluid}
                alt={i18n('Traffilog Team building')}/>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} className={styles.tbImageContainer}>
              <Img imgStyle={{ objectFit: 'none'}}
                objectFit='none'
                // objectPosition='right center'
                className={styles.tbImage}
                fluid={tb2.fluid}
                alt={i18n('Traffilog Team building')}/>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} className={styles.tbImageContainer}>
              <Img imgStyle={{ objectFit: 'none'}}
                objectFit='none'
                // objectPosition='right center'
                className={styles.tbImage}
                fluid={tb3.fluid}
                alt={i18n('Traffilog Team building')}/>
            </Grid>
          </Grid>

      </SectionWrapper>
  )}}/>
);