import React from 'react';
import Content from '../views/AboutUs';

export default ({location}) => <Content location={location}  seo={{
  title: "About Traffilog | A Leader in Connected Vehicle Technology",
  lang:'en',
  description: "Discover why Traffilog is a trusted solutions partner for businesses seeking advanced and flexible connected vehicle technology. Learn about our commitment to innovation and customer success.",
  meta: [{
    name: 'keywords',
    content: 'About Traffilog, mobility solutions, telematics platform, fleet services',
  }]
}}/>